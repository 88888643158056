import React from 'react';
import { FeatureAppDefinition, FeatureServices } from '@feature-hub/core';
import { ReactFeatureApp } from '@feature-hub/react';
import type { Logger } from '@feature-hub/logger';
import { GfaServiceConfigProviderV1 } from '@volkswagen-onehub/gfa-service-config-provider';
import type { AsyncSsrManagerV1 } from '@feature-hub/async-ssr-manager';
import {
  DisclaimerManagerV1,
  ScopedDisclaimerManager,
} from '@volkswagen-onehub/disclaimer-manager';
import type {
  AudiFootnoteReferenceServiceInterfaceV3,
  AudiFootnoteRefernceServiceScopeManagerInterfaceV3,
} from '@oneaudi/footnote-reference-service';
import type { AudiFootnoteServiceInterfaceV1 } from '@oneaudi/footnote-service';
import { TokenizedTheme, audiDarkTheme, audiLightTheme } from '@audi/audi-ui-react-v2';
import FeatureApp from './FeatureApp';

export interface FeatureServiceDependencies extends FeatureServices {
  readonly 'gfa:service-config-provider': GfaServiceConfigProviderV1;
  readonly 's2:logger': Logger;
  readonly 's2:async-ssr-manager': AsyncSsrManagerV1;
  'disclaimer-manager': DisclaimerManagerV1;
  'audi-footnote-reference-service': AudiFootnoteRefernceServiceScopeManagerInterfaceV3;
  'audi-footnote-service': AudiFootnoteServiceInterfaceV1;
}

export type FeatureAppConfig = {
  apiUrls?: string[];
  disclaimerManagerScope?: string;
  layerElementClassName?: string;
  omitBottomSpacing?: string;
  omitSideSpacing?: string;
  uiTheme?: string;
};

type FeatureAppDefinitionType = FeatureAppDefinition<
  ReactFeatureApp,
  FeatureServiceDependencies,
  FeatureAppConfig
>;

function parseFeatureAppConfig(config: FeatureAppConfig | undefined): FeatureAppConfig {
  const apiUrls = config?.apiUrls;
  const uiTheme = config?.uiTheme ? config?.uiTheme : 'dark';
  const omitBottomSpacing = config?.omitBottomSpacing;
  const omitSideSpacing = config?.omitSideSpacing;
  const disclaimerManagerScope = config?.disclaimerManagerScope;
  const layerElementClassName = config?.layerElementClassName;

  return {
    apiUrls,
    disclaimerManagerScope,
    layerElementClassName,
    omitBottomSpacing,
    omitSideSpacing,
    uiTheme,
  };
}

const featureAppDefinition: FeatureAppDefinitionType = {
  create: ({ featureServices, config, featureAppId }) => {
    const loggerService = featureServices['s2:logger'];
    loggerService?.info('Feature App created.');
    const asyncSsrManager: AsyncSsrManagerV1 = featureServices['s2:async-ssr-manager'];
    const referenceServiceScopeManager: AudiFootnoteRefernceServiceScopeManagerInterfaceV3 =
      featureServices['audi-footnote-reference-service'];
    const logger: Logger = featureServices['s2:logger'];
    const footnoteService: AudiFootnoteServiceInterfaceV1 =
      featureServices['audi-footnote-service'];
    const disclaimerManager: DisclaimerManagerV1 = featureServices['disclaimer-manager'];

    const { disclaimerManagerScope, omitBottomSpacing, omitSideSpacing, uiTheme } =
      parseFeatureAppConfig(config);

    let scopedDisclaimerManager: ScopedDisclaimerManager;
    if (typeof disclaimerManagerScope !== 'undefined') {
      if (disclaimerManagerScope === 'DEFAULT') {
        scopedDisclaimerManager = disclaimerManager.getScopedDisclaimerManager();
      } else {
        scopedDisclaimerManager =
          disclaimerManager.getScopedDisclaimerManager(disclaimerManagerScope);
      }
    } else {
      scopedDisclaimerManager = disclaimerManager.getScopedDisclaimerManager(featureAppId);
    }

    let audiUiTheme: TokenizedTheme;
    if (uiTheme === 'light') {
      audiUiTheme = audiLightTheme;
    } else {
      audiUiTheme = audiDarkTheme;
    }

    const referenceServiceManager: AudiFootnoteReferenceServiceInterfaceV3 =
      referenceServiceScopeManager.getDefaultScopeRefService();
    const disableBottomSpacing: boolean = omitBottomSpacing === 'true';
    const disableSideSpacing: boolean = omitSideSpacing === 'true';

    const isServer = typeof asyncSsrManager !== 'undefined';

    if (isServer) {
      return {
        render: () => (
          <FeatureApp
            apiUrls={[]}
            audiUiTheme={audiUiTheme}
            disableBottomSpacing={disableBottomSpacing}
            disableSideSpacing={disableSideSpacing}
            disclaimerManager={scopedDisclaimerManager}
            error={null as unknown as undefined}
            featureAppId={featureAppId}
            isServer={isServer}
            referenceServiceManager={referenceServiceManager}
          />
        ),
      };
    }

    try {
      const { apiUrls, layerElementClassName } = parseFeatureAppConfig(config);
      if (!Array.isArray(apiUrls) || apiUrls.length === 0) {
        throw new Error(`[${featureAppId}] 'config.apiUrls' is not of type array or is empty.`);
      }
      return {
        render: () => (
          <FeatureApp
            apiUrls={apiUrls}
            audiUiTheme={audiUiTheme}
            disableBottomSpacing={disableBottomSpacing}
            disableSideSpacing={disableSideSpacing}
            disclaimerManager={scopedDisclaimerManager}
            error={null as unknown as undefined}
            isServer={isServer}
            featureAppId={featureAppId}
            footnoteService={footnoteService}
            layerElementClassName={layerElementClassName}
            referenceServiceManager={referenceServiceManager}
          />
        ),
      };
    } catch (error) {
      logger.error(error);
      return {
        render: () => (
          <FeatureApp
            apiUrls={[]}
            audiUiTheme={audiUiTheme}
            disableBottomSpacing={disableBottomSpacing}
            disableSideSpacing={disableSideSpacing}
            disclaimerManager={scopedDisclaimerManager}
            error={error as Error}
            isServer={isServer}
            featureAppId={featureAppId}
            footnoteService={footnoteService}
            referenceServiceManager={referenceServiceManager}
          />
        ),
      };
    }
  },
  dependencies: {
    externals: {
      '@audi/audi-ui-react-v2': '^2',
      '@feature-hub/react': '^2.9.0',
      react: '^16.13.1 || ^17.0.2',
      'react-dom': '^16.13.1 || ^17.0.2',
      'styled-components': '^5.1.1',
    },
    featureServices: {
      'audi-footnote-reference-service': '^3.0.0',
      'audi-footnote-service': '^1.0.0',
      'disclaimer-manager': '^1.4.0',
      's2:logger': '^1.0.0',
    },
  },
  optionalDependencies: {
    featureServices: {
      'audi-configurator-footnote-service': '^1.0.0',
      's2:async-ssr-manager': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
    },
  },
};

export default featureAppDefinition;
