/**
 * This is the starting point of your application.
 * Feature Hub Apps will use this file to bootstrap the app.
 */
import React from 'react';
import { App } from '@oneaudi/oneaudi-os-react';
import AudiFootnoteEngineContainer, {
  AudiFootnoteEngineContainerProps,
} from './components/audi-footnote-engine-container/audi-footnote-engine-container';

const FeatureApp: React.FC<AudiFootnoteEngineContainerProps> = ({
  apiUrls,
  audiUiTheme,
  disableBottomSpacing,
  disableSideSpacing,
  disclaimerManager,
  error,
  featureAppId,
  isServer,
  referenceServiceManager,
  footnoteService,
  layerElementClassName,
  ...inherited
}) => {
  const app = {
    name: __FEATURE_APP_NAME__,
    version: __FEATURE_APP_VERSION__,
  };

  const appConfig: { [k: string]: string } = { appName: __FEATURE_APP_NAME__ };

  return (
    <App config={appConfig} app={app} {...inherited}>
      <AudiFootnoteEngineContainer
        apiUrls={apiUrls}
        audiUiTheme={audiUiTheme}
        disableBottomSpacing={disableBottomSpacing}
        disableSideSpacing={disableSideSpacing}
        disclaimerManager={disclaimerManager}
        error={error}
        featureAppId={featureAppId}
        isServer={isServer}
        referenceServiceManager={referenceServiceManager}
        footnoteService={footnoteService}
        layerElementClassName={layerElementClassName}
      />
    </App>
  );
};

export default FeatureApp;
