import React from 'react';

import { Text } from '@audi/audi-ui-react-v2';
import styled from 'styled-components';
import { AudiFinalFootnotesToRenderInterface } from './audi-footnote-engine';

interface AudiFootnoteListItemProps {
  footnoteItem: AudiFinalFootnotesToRenderInterface;
  footnoteIndex?: string;
}

const FootnoteStyledListItem = styled.li`
  margin-left: var(${({ theme }): string => theme.responsive.spacing.l});
  padding: var(${({ theme }): string => theme.responsive.spacing.m}) 0 0 0;
  position: relative;
`;

const FootnoteEngineStyledNumber = styled.span`
  left: calc(2px - var(${({ theme }): string => theme.responsive.spacing.l}));
  position: absolute;
`;

const AudiFootnoteListItem: React.FC<AudiFootnoteListItemProps> = ({
  footnoteItem,
  footnoteIndex,
}) => {
  return (
    <FootnoteStyledListItem id={footnoteItem.contextID}>
      {footnoteIndex && (
        <FootnoteEngineStyledNumber>
          <Text as="span" variant="copy2">
            {footnoteIndex}
          </Text>
        </FootnoteEngineStyledNumber>
      )}
      <Text as="span" variant="copy2">
        <span
          /* eslint-disable-next-line react/no-danger */
          dangerouslySetInnerHTML={{
            __html: footnoteItem.Text.replace(
              /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,
              'illegalscript'
            ),
          }}
        />
      </Text>
    </FootnoteStyledListItem>
  );
};

export default AudiFootnoteListItem;
